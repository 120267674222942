body {
  background-color: #eee;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;
}

h1 {
  color: #888;
  font-size: 42px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 16px;
}

.search-box {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
  max-width: 480px;
  margin-bottom: 16px;
  transition: 0.4s;
}

.search-box input {
  display: block;
  appearance: none;
  outline: none;
  border: none;
  padding: 16px;
  width: 100%;
  transition: 0.4s;
}

.search-box input::-webkit-search-cancel-button {
  cursor: pointer;
}

.search-box:focus-within {
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.results {
  max-width: 768px;
  margin: 0 auto;
}

.result {
  width: 100%;
  padding: 16px;
  margin-bottom: 16px;
  background-color: #eee;
  border-radius: 16px;
  transition: 0.4s;
}

.result:hover {
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
}

.result h3 {
  color: #888;
  font-size: 28px;
  margin-bottom: 16px;
}

.result p {
  color: #313131;
  font-size: 18px;
  margin-bottom: 16px;
}

.result p .searchmatch {
  font-weight: 700;
  color: #a84fff;
}

.result a {
  display: inline-block;
  padding: 12px 16px;
  background-color: #a84fff;
  color: #fff;
  border-radius: 12px;
  font-weight: 700;
  text-decoration: none;
  transition: 0.4s;
}

.result a:hover {
  background-color: #ff4fa8;
}
